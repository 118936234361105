import { FC } from "react";

import VectorIcon from "components/Icons/VectorIcon";

type HeaderBackProps = {
  goBack: () => void,
  title: string
}
const HeaderBack: FC<HeaderBackProps> = ({ goBack, title }) => (
  <div className="new-header__back" onClick={goBack}>
    <VectorIcon className="new-header__back__icon" onClick={goBack}/>
    <div className="new-header__back__title">
      {title}
    </div>
  </div>
);

export default HeaderBack;